@import "~pattern.css/dist/pattern.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.file-input {
  @apply block w-full text-sm text-stone-500 file:mr-4 file:p-3 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-stone-700 file:text-white/90 hover:file:bg-stone-800 file:cursor-pointer;
}
.text-input {
  @apply p-3 rounded-lg shadow text-sm text-stone-500;
}
.button {
  @apply p-3 rounded-lg shadow text-sm uppercase font-semibold focus:outline-none focus:ring text-center cursor-pointer flex gap-3 flex-row items-center justify-center flex-grow;
}
.button-flat {
  @apply rounded-none;
}
.button-upload {
  @apply bg-orange-500 text-orange-900 hover:bg-orange-600 active:bg-orange-600 focus:ring-orange-300 w-full;
}
.button-download {
  @apply bg-yellow-500 text-yellow-900 hover:bg-yellow-600 active:bg-yellow-600 focus:ring-yellow-300 w-full;
}
.button-tweet {
  @apply bg-blue-500 text-white hover:bg-blue-800  active:bg-blue-700 focus:ring-blue-300;
}
.label-text {
  @apply text-stone-400 font-semibold text-xs uppercase mx-1;
}
.sidebar-width {
  @apply w-full md:w-60;
}

.modal {
  @apply relative w-10/12 md:w-1/2 mx-auto rounded-lg;
}
.modal > .content {
  @apply w-full p-8 bg-white  text-sm text-stone-700 rounded-lg;
}
.modal > .content li {
  @apply list-disc ml-5;
}
.modal > .close {
  @apply cursor-pointer absolute top-0 right-0 text-xl translate-x-1/2 -translate-y-1/2 bg-white border rounded-full w-8 h-8 flex justify-center items-center shadow leading-none;
}

.react-colorful {
  width: 100% !important;
}
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
